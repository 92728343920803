import itemSchema from './schemas/payment.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import axios from "axios";
import CONSTANTS from "@/config/constants";

const resourceName = 'payments'
const base = new ModuleBase(itemSchema, resourceName);
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    listStatus: [
      {
        value: '',
        text: 'All status'
      },
      {
        value: CONSTANTS.PAYMENT_STATUS.WAIT_PAY,
        text: 'Chờ thanh toán'
      },
      {
        value: CONSTANTS.PAYMENT_STATUS.PAID,
        text: 'Đã thanh toán'
      },
      {
        value: CONSTANTS.PAYMENT_STATUS.REFUND,
        text: 'Đã hoàn trả'
      },
    ],
    listPaymentType: [
      {
        value: '',
        text: 'All payment type'
      },
      {
        value: CONSTANTS.PAYMENT_TYPE.PAY_ORDER,
        text: 'Trả phí order'
      },
      {
        value: CONSTANTS.PAYMENT_TYPE.DEPOSIT,
        text: 'Nộp tiền vào tài khoản'
      },
      {
        value: CONSTANTS.PAYMENT_TYPE.DISCOUNT,
        text: 'Khuyến mãi - chiết khấu'
      },
      {
        value: CONSTANTS.PAYMENT_TYPE.WITHDRAW,
        text: 'Rút tiền'
      },
    ]
  },
  getters: {
    ...base.getters,
    getStatusText: state => status => {
      let data = state.listStatus.filter(s => s.value === status)
      if (data.length > 0) return data[0]['text']
      return ''
    },
    getTypeText: state => status => {
      let data = state.listPaymentType.filter(s => s.value === status)
      if (data.length > 0) return data[0]['text']
      return ''
    },
    listStatus: state => state.listStatus,
    listPaymentType: state => state.listPaymentType,
  },
  mutations: {
    ...base.mutations,
  },
  actions: {
    ...base.actions,
    // eslint-disable-next-line no-empty-pattern
    payOrders: async ({}, payload) => {
      return await axios.post(resourceName + '/pay/orders', payload).catch(err => {
        console.log('Error when pay order:', err);
      })
    },
    // eslint-disable-next-line no-empty-pattern
    requestWithdraw: async ({}, payload) => {
      return await axios.post(resourceName + '/request-withdraw', payload).catch(err => {
        console.log('Error when request withdraw:', err);
      })
    },
    approve: async ({}, payload) => {
        return await axios.post(resourceName + '/approve/' + payload.id, payload.data).catch(err => {
            console.log('Error when request withdraw:', err);
        })
    }
  },

}
