import itemSchema from './schemas/order.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import axios from 'axios';
import CONSTANTS from '@/config/constants'
import {buildQuery} from "./ModuleBase";

const resourceName = 'orders'
const base = new ModuleBase(itemSchema, resourceName);
const FileSaver = require('file-saver');

export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    listStatus: [
      {
        value: '',
        text: 'All status'
      },
      {
        value: CONSTANTS.ORDER_STATUS.NEW_WAIT_CONF,
        text: 'Chờ xác nhận'
      },
      {
        value: CONSTANTS.ORDER_STATUS.CONF_WAIT_PARCEL,
        text: 'Đã xác nhận - Chờ bưu kiện'
      },
      {
        value: CONSTANTS.ORDER_STATUS.IN_WAREHOUSE_WAIT_PACKAGE,
        text: 'Đã nhận đủ bưu kiện - Chờ đóng gói'
      },
      {
        value: CONSTANTS.ORDER_STATUS.PACKAGED_WAIT_TRANSIT,
        text: 'Đã đóng gói - Chờ vận chuyển'
      },
      {
        value: CONSTANTS.ORDER_STATUS.TRANSITED_WAIT_COMPLETE,
        text: 'Đã vận chuyển - Chờ hoàn thành'
      },
      {
        value: CONSTANTS.ORDER_STATUS.COMPLETED,
        text: 'Hoàn thành'
      },
      {
        value: CONSTANTS.ORDER_STATUS.RETURN,
        text: 'Đang chuyển hoàn'
      },
      {
        value: CONSTANTS.ORDER_STATUS.RESERVE,
        text: 'Bảo lưu'
      },
      {
        value: CONSTANTS.ORDER_STATUS.CANCELED,
        text: 'Đã hủy'
      },
      {
        value: CONSTANTS.ORDER_STATUS.UPDATE_WAIT_CONF,
        text: 'Đã cập nhật - chờ xác nhận'
      },
      {
          value: CONSTANTS.ORDER_STATUS.LOSS,
          text: 'Mất tích'
      },
      {
          value: CONSTANTS.ORDER_STATUS.REIMBURSEMENT,
          text: 'Bồi hoàn'
      },
    ],
    listBuyTrackingStatus: [
      {
          value: '',
          text: 'All status'
      },
      {
        value: CONSTANTS.TRACKING_NUMBER_BUY_STATUS.DRAFT,
        text: 'Draft'
      },
      {
          value: CONSTANTS.TRACKING_NUMBER_BUY_STATUS.READY_TO_BUY,
          text: 'Ready to buy'
      },
      {
        value: CONSTANTS.TRACKING_NUMBER_BUY_STATUS.WAIT_BUY,
        text: 'Waiting'
      },
      {
        value: CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUYING,
        text: 'Buying'
      },
      {
        value: CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_FAIL,
        text: 'Buy Failed'
      },
      {
        value: CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS,
        text: 'Success'
      },
    ],
  },
  getters: {
    ...base.getters,
    getStatusText: state => status => {
      let data = state.listStatus.filter(s => s.value === status)
      if (data.length > 0) return data[0]['text']
      return ''
    },
    buyTrackingGetStatusText: state => buyTrackingStatus => {
      let data = state.listBuyTrackingStatus.filter(s => s.value === buyTrackingStatus)
      if (data.length > 0) return data[0]['text']
      return ''
    },
    listOrderStatus: state => state.listStatus,
    listBuyTrackingStatus: state => state.listBuyTrackingStatus,
  },
  mutations: {
    ...base.mutations,
  },
  actions: {
    ...base.actions,
    getExcelTemplate() {
      return axios.get('/' + resourceName + '/excel-template', {
        responseType: 'blob'
      })
        .then(response => {
          FileSaver.saveAs(response.data, 'form.xlsx')
        })
        .catch(err => {
          console.log('Error when download file:', err);
        })
    },
    getByIdWithoutAuth: async ({commit}, requestData) => {
      let params = requestData.params; //to provide with & withCount
      // http://domain.com/api/resource_name/1?with=relation1;relation2&withcount=relation3;relation4
      let res = await axios.get('/' + resourceName + '/without-auth/' + requestData.id + '/' + requestData.secretKey, {params: buildQuery(params)});
      console.log('getByIdWithoutAuth res', res);
      let item = res.status === 200 && res.data ? res.data.data : null;
      if (!item) return null;
      //find item in list state and update it
      await commit('UPSERT', item);
      return item;
    },
    // eslint-disable-next-line no-empty-pattern
    async changeOrderStatus({}, payload) {
      return axios.post('orders/change-status', payload)
        .then(response => {
          return response
        })
        .catch(err => {
          console.log('Error when download file:', err);
        })
    },
    // eslint-disable-next-line no-empty-pattern
    async changeOrderStatusBatch({}, payload) {
      return await axios.post('/' + resourceName + '/change-status-batch', payload);
    },
    async requestBuyTrackingNumber({}, payload) {
      return await axios.post('/' + resourceName + '/request-buy-tracking', payload);
    },
    async calcShippingFees({}, payload) {
      return await axios.post('/' + resourceName + '/calc-shipping-fees', payload, {
        params: {
          silent: payload.silent
        }
      });
    },
    // eslint-disable-next-line no-empty-pattern
    async importLabelFromPDF({}, payload) {
      return await axios.post('/' + resourceName + '/import-label-from-pdf', payload)
    },
    async exportItem({commit, rootState}, payload) {
        await axios.post('/' + resourceName + `/export`, payload, {
              responseType: 'blob'
          })
          .then((res) => {
              FileSaver.saveAs(res.data, 'order.xlsx')
          })
          .catch(err => {

          })
      },
    async onSearchOrders({}, searchParams) {
      let params = buildQuery(searchParams);
      let res = await axios.get('/' + resourceName, {params: params});
      return res.status === 200 && res.data && res.data.data ? res.data.data : [];
    },
    // eslint-disable-next-line no-empty-pattern
    async getLabelData({}, payload) {
      return axios.post('orders/export-labels', payload)
        .then(response => {
          return response
        })
        .catch(err => {
          console.log('Error when export labels:', err);
        })
    },
  }
}
