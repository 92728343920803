module.exports = {
  NOTICE_TYPE: {
    INFO: 1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR: 4
  },
  ROLES: {
    SUPER_ADMINISTRATOR: 1,
    CUSTOMER: 2,
    RECEIVER: 3,
    SALE: 4,
    GENERAL_MANAGER: 5,
    MANAGER: 6,
    ACCOUNTANT: 7,
    PACKAGER: 8,
    SUPERVISOR: 9
  },
  ORDER_STATUS: {
    NEW_WAIT_CONF: 0,
    CONF_WAIT_PARCEL: 1,
    IN_WAREHOUSE_WAIT_PACKAGE: 2,
    PACKAGED_WAIT_TRANSIT: 3,
    TRANSITED_WAIT_COMPLETE: 4,
    COMPLETED: 5,
    RETURN: 6,
    RESERVE: 7,
    CANCELED: 8,
    UPDATE_WAIT_CONF: 9,
    LOSS: 10,
    REIMBURSEMENT: 11,
  },
  TRACKING_NUMBER_BUY_STATUS: {
    DRAFT: 0,
    WAIT_BUY: 1,
    BUY_SUCCESS: 2,
    BUY_FAIL: 3,
    READY_TO_BUY: 4,
    BUYING: 5,
  },
  TRACKING_NUMBER_REGISTER_STATUS: {
    UNREGISTERED: 0,
    REGISTERED: 1,
    REGISTER_FAIL: 2,
  },
  BIG_CONTAINER_STATUS: {
    NEW: 0,
    PACKAGED: 1,
    IN_TRANSIT: 2,
    COMPLETED: 3,
    RETURN: 4,
    CANCELED: 5,
  },
  CUSTOMER_RANK: {
    STANDARD: 1,
    VIP_1: 2,
    VIP_2: 3,
  },
  SERVICE_TYPE: {
    SHIP_EXPRESS: 1,
    EPACKET_US: 2,
    EPACKET_AU: 3,
    EPACKET_WW: 4,
    DHL_ECOM_US: 5,
    DHL_ECOM_WW: 6,
    LAST_MILE_TRACKING: 7
  },
  PAYMENT_TYPE: {
    DEPOSIT: 1,
    WITHDRAW: 2,
    PAY_ORDER: 3,
    OFFSET_BALANCE_ADDITION: 4,
    OFFSET_BALANCE_SUBTRACTION: 5,
    DISCOUNT: 6
  },
  PAYMENT_STATUS: {
    WAIT_PAY: 1,
    PAID: 2,
    REFUND: 3
  }
}
